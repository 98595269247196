/**
 * Manufacturing
 * Manufacturing
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateComponentInformationCommand } from '../model/create-component-information-command';
// @ts-ignore
import { CreateComponentInformationCommandResult } from '../model/create-component-information-command-result';
// @ts-ignore
import { CreateManufacturedComponentCommand } from '../model/create-manufactured-component-command';
// @ts-ignore
import { CreateManufacturedComponentCommandResult } from '../model/create-manufactured-component-command-result';
// @ts-ignore
import { GetManufacturedComponentsQueryResult } from '../model/get-manufactured-components-query-result';
// @ts-ignore
import { LifeCycleStateEnum } from '../model/life-cycle-state-enum';
// @ts-ignore
import { UpdateManufacturedComponentCommand } from '../model/update-manufactured-component-command';
// @ts-ignore
import { UpdateManufacturedComponentCommandResult } from '../model/update-manufactured-component-command-result';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { ManufacturingApiConfiguration }                                     from '../configuration';
import {
    ManufacturedComponentsBackboneServiceInterface
} from './manufactured-components.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class ManufacturedComponentsBackboneService implements ManufacturedComponentsBackboneServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new ManufacturingApiConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: ManufacturingApiConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a component to manufacture
     * Create a component to manufacture
     * @param createManufacturedComponentCommand 
     * @param xApiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createManufacturedComponent(createManufacturedComponentCommand: CreateManufacturedComponentCommand, xApiKey?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<CreateManufacturedComponentCommandResult>;
    public createManufacturedComponent(createManufacturedComponentCommand: CreateManufacturedComponentCommand, xApiKey?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CreateManufacturedComponentCommandResult>>;
    public createManufacturedComponent(createManufacturedComponentCommand: CreateManufacturedComponentCommand, xApiKey?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CreateManufacturedComponentCommandResult>>;
    public createManufacturedComponent(createManufacturedComponentCommand: CreateManufacturedComponentCommand, xApiKey?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (createManufacturedComponentCommand === null || createManufacturedComponentCommand === undefined) {
            throw new Error('Required parameter createManufacturedComponentCommand was null or undefined when calling createManufacturedComponent.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', String(xApiKey));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/manufacturing/v1/components`;
        return this.httpClient.request<CreateManufacturedComponentCommandResult>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createManufacturedComponentCommand,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create information for this component
     * Create information for this component
     * @param componentKey 
     * @param createComponentInformationCommand 
     * @param xApiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createManufacturedComponentInformation(componentKey: string, createComponentInformationCommand: CreateComponentInformationCommand, xApiKey?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<CreateComponentInformationCommandResult>;
    public createManufacturedComponentInformation(componentKey: string, createComponentInformationCommand: CreateComponentInformationCommand, xApiKey?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CreateComponentInformationCommandResult>>;
    public createManufacturedComponentInformation(componentKey: string, createComponentInformationCommand: CreateComponentInformationCommand, xApiKey?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CreateComponentInformationCommandResult>>;
    public createManufacturedComponentInformation(componentKey: string, createComponentInformationCommand: CreateComponentInformationCommand, xApiKey?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (componentKey === null || componentKey === undefined) {
            throw new Error('Required parameter componentKey was null or undefined when calling createManufacturedComponentInformation.');
        }
        if (createComponentInformationCommand === null || createComponentInformationCommand === undefined) {
            throw new Error('Required parameter createComponentInformationCommand was null or undefined when calling createManufacturedComponentInformation.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', String(xApiKey));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/manufacturing/v1/component/${this.configuration.encodeParam({name: "componentKey", value: componentKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/information`;
        return this.httpClient.request<CreateComponentInformationCommandResult>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createComponentInformationCommand,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of manufactured components
     * Gets a list of manufactured components
     * @param keys 
     * @param buildupComponentKeys 
     * @param lifeCycleStates 
     * @param excludeComponentsWithDeviceLinks 
     * @param pageSize 
     * @param pageIndex 
     * @param xApiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getManufacturedComponents(keys?: Array<string>, buildupComponentKeys?: Array<string>, lifeCycleStates?: Array<LifeCycleStateEnum>, excludeComponentsWithDeviceLinks?: boolean, pageSize?: number, pageIndex?: number, xApiKey?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<GetManufacturedComponentsQueryResult>;
    public getManufacturedComponents(keys?: Array<string>, buildupComponentKeys?: Array<string>, lifeCycleStates?: Array<LifeCycleStateEnum>, excludeComponentsWithDeviceLinks?: boolean, pageSize?: number, pageIndex?: number, xApiKey?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetManufacturedComponentsQueryResult>>;
    public getManufacturedComponents(keys?: Array<string>, buildupComponentKeys?: Array<string>, lifeCycleStates?: Array<LifeCycleStateEnum>, excludeComponentsWithDeviceLinks?: boolean, pageSize?: number, pageIndex?: number, xApiKey?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetManufacturedComponentsQueryResult>>;
    public getManufacturedComponents(keys?: Array<string>, buildupComponentKeys?: Array<string>, lifeCycleStates?: Array<LifeCycleStateEnum>, excludeComponentsWithDeviceLinks?: boolean, pageSize?: number, pageIndex?: number, xApiKey?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (keys) {
            keys.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Keys');
            })
        }
        if (buildupComponentKeys) {
            buildupComponentKeys.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'BuildupComponentKeys');
            })
        }
        if (lifeCycleStates) {
            lifeCycleStates.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'LifeCycleStates');
            })
        }
        if (excludeComponentsWithDeviceLinks !== undefined && excludeComponentsWithDeviceLinks !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>excludeComponentsWithDeviceLinks, 'ExcludeComponentsWithDeviceLinks');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'PageSize');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', String(xApiKey));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/manufacturing/v1/components`;
        return this.httpClient.request<GetManufacturedComponentsQueryResult>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing component
     * Update an existing component
     * @param componentKey 
     * @param updateManufacturedComponentCommand 
     * @param xApiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateManufacturedComponent(componentKey: string, updateManufacturedComponentCommand: UpdateManufacturedComponentCommand, xApiKey?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<UpdateManufacturedComponentCommandResult>;
    public updateManufacturedComponent(componentKey: string, updateManufacturedComponentCommand: UpdateManufacturedComponentCommand, xApiKey?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<UpdateManufacturedComponentCommandResult>>;
    public updateManufacturedComponent(componentKey: string, updateManufacturedComponentCommand: UpdateManufacturedComponentCommand, xApiKey?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<UpdateManufacturedComponentCommandResult>>;
    public updateManufacturedComponent(componentKey: string, updateManufacturedComponentCommand: UpdateManufacturedComponentCommand, xApiKey?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (componentKey === null || componentKey === undefined) {
            throw new Error('Required parameter componentKey was null or undefined when calling updateManufacturedComponent.');
        }
        if (updateManufacturedComponentCommand === null || updateManufacturedComponentCommand === undefined) {
            throw new Error('Required parameter updateManufacturedComponentCommand was null or undefined when calling updateManufacturedComponent.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', String(xApiKey));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/manufacturing/v1/components/${this.configuration.encodeParam({name: "componentKey", value: componentKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<UpdateManufacturedComponentCommandResult>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateManufacturedComponentCommand,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
